import React, {FC} from 'react'

interface ReportData {
  totalAsins: number
  successfulAsins: number
  failedAsins: number
  titlesExceedingLength: number
  descriptionsExceedingLength: number
  bulletPointsQty: number
  bulletsLengthOptimized: number
  brandStoryIncluded: number
  aplusContentIncluded: number
  imagesQtyOptimized: number
  videoShortIncluded: number
  primeAvailable: number
  customerReviewsOptimized: number
  productRatingOptimized: number
}

interface ReportDetailsProps {
  reportData: ReportData | null
}

const ReportDetails: FC<ReportDetailsProps> = ({reportData}) => {
  const totalAsins = reportData?.totalAsins || 0

  return (
    <div className='card card-flush'>
      <div className='card-header pt-6 pb-0'>
        <div className='row'>
          <div className='card-title'>
            <h2 className='card-label'>Report Details</h2>
          </div>
        </div>
      </div>
      <div className='card-body pt-6 pb-4'>
        {reportData ? (
          <div className='table-responsive'>
            <table className='table table-striped table-report'>
              <thead>
                <tr>
                  <th>Metric</th>
                  <th>Value</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total ASINs processed</td>
                  <td>{totalAsins}</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>Successful ASINs</td>
                  <td>
                    {reportData.successfulAsins}/{totalAsins}
                  </td>
                  <td>{((reportData.successfulAsins / totalAsins) * 100).toFixed(1)}%</td>
                </tr>

                <tr>
                  <td>Titles meeting length requirement</td>
                  <td>
                    {reportData.titlesExceedingLength}/{totalAsins}
                  </td>
                  <td>{((reportData.titlesExceedingLength / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>Descriptions meeting length requirement</td>
                  <td>
                    {reportData.descriptionsExceedingLength}/{totalAsins}
                  </td>
                  <td>
                    {((reportData.descriptionsExceedingLength / totalAsins) * 100).toFixed(1)}%
                  </td>
                </tr>
                <tr>
                  <td>ASINs meeting bullet points quantity requirement</td>
                  <td>
                    {reportData.bulletPointsQty}/{totalAsins}
                  </td>
                  <td>{((reportData.bulletPointsQty / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs with all bullets meeting length requirement</td>
                  <td>
                    {reportData.bulletsLengthOptimized}/{totalAsins}
                  </td>
                  <td>{((reportData.bulletsLengthOptimized / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs with Brand Story included</td>
                  <td>
                    {reportData.brandStoryIncluded}/{totalAsins}
                  </td>
                  <td>{((reportData.brandStoryIncluded / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs with A+ Content included</td>
                  <td>
                    {reportData.aplusContentIncluded}/{totalAsins}
                  </td>
                  <td>{((reportData.aplusContentIncluded / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs meeting images quantity requirement</td>
                  <td>
                    {reportData.imagesQtyOptimized}/{totalAsins}
                  </td>
                  <td>{((reportData.imagesQtyOptimized / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs with Video Short included</td>
                  <td>
                    {reportData.videoShortIncluded}/{totalAsins}
                  </td>
                  <td>{((reportData.videoShortIncluded / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs with Prime Available</td>
                  <td>
                    {reportData.primeAvailable}/{totalAsins}
                  </td>
                  <td>{((reportData.primeAvailable / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs meeting customer reviews requirement</td>
                  <td>
                    {reportData.customerReviewsOptimized}/{totalAsins}
                  </td>
                  <td>{((reportData.customerReviewsOptimized / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
                <tr>
                  <td>ASINs meeting product rating requirement</td>
                  <td>
                    {reportData.productRatingOptimized}/{totalAsins}
                  </td>
                  <td>{((reportData.productRatingOptimized / totalAsins) * 100).toFixed(1)}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <p>No report generated yet.</p>
        )}
      </div>
    </div>
  )
}

export {ReportDetails}
