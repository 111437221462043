/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import axios from 'axios'
import {getUserByToken} from '../../../../app/modules/auth/core/_requests'
import {API_URL} from '../../../../app/modules/auth/core/_requests'

const HeaderUserMenu: FC = () => {
  const {auth, currentUser, logout, saveAuth, setCurrentUser} = useAuth()

  const changeVendor = async (vendorName: string) => {
    console.log('Changing vendor to:', vendorName)
    try {
      const response = await axios.post(
        `${API_URL}/users/update_vendor`,
        {vendorName},
        {
          headers: {
            Authorization: `Bearer ${auth?.api_token}`,
          },
        }
      )
      const {api_token} = response.data
      saveAuth({api_token})
      // Fetch the updated user data
      const {data: user} = await getUserByToken(api_token)
      setCurrentUser(user)
      // Optionally, reload the page
      window.location.reload()
    } catch (error) {
      console.error('Error changing vendor:', error)
      // Handle the error, possibly show a notification to the user
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-450px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <i className='bi bi-person-fill'></i>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.decoded?.email}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                {currentUser?.decoded?.role}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.decoded?.vendorName}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      {currentUser?.decoded?.role === 'admin' ? (
        <>
          <div
            className='menu-item px-5'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='bottom'
          >
            <a href='#' className='menu-link px-5'>
              <span className='menu-title'>Change Vendor View</span>
              <span className='menu-arrow'></span>
            </a>

            <div className='menu-sub menu-sub-dropdown w-175px py-4'>
              <div className='menu-item px-3'>
                <a
                  href='#'
                  className='menu-link px-5'
                  onClick={() => changeVendor('Designer Eyes')}
                >
                  Designer Eyes
                </a>
              </div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5' onClick={() => changeVendor('Luxottica')}>
                  Luxottica
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5' onClick={() => changeVendor('Thelios')}>
                  Thelios
                </a>
              </div>
              {/* <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5' onClick={() => changeVendor('Kerring')}>
                  Kerring
                </a>
              </div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5' onClick={() => changeVendor('Marcolin')}>
                  Marcolin
                </a>
              </div> */}
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5' onClick={() => changeVendor('Costa')}>
                  Costa
                </a>
              </div>

              {/* <div className='menu-item px-3'>
                <div className='menu-content px-3'>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input w-30px h-20px'
                      type='checkbox'
                      value='1'
                      defaultChecked={true}
                      name='notifications'
                    />
                    <span className='form-check-label text-muted fs-7'>Notifications</span>
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </>
      ) : null}
      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
