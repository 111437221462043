/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      {currentUser?.decoded?.vendorName === 'Costa' && (
        <SidebarMenuItem
          to='/overview'
          title='Overview'
          customIconHtml={
            <span className='menu-icon'>
              <i className='fa-solid fa-chart-column'></i>
            </span>
          }
        />
      )}

      <SidebarMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        customIconHtml={
          <span className='menu-icon'>
            <i className='fa-solid fa-chart-column'></i>
          </span>
        }
      />

      {/*################# PO Management #################*/}
      {(currentUser?.decoded?.vendorName === 'Valeria' ||
        currentUser?.decoded?.role === 'inventoryManagement') && (
        <SidebarMenuItemWithSub
          to='#'
          title='Purchase Orders'
          customIconHtml={
            <span className='menu-icon'>
              <i className='fa-solid fa-box'></i>
            </span>
          }
        >
          <SidebarMenuItem
            to='/purchase-orders/list'
            title='Customer Master List'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-users'></i>
              </span>
            }
          />
          <SidebarMenuItem
            to='/purchase-orders/main'
            title='Control Center'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-sliders-h'></i>
              </span>
            }
          />
          <SidebarMenuItem
            to='/purchase-orders/master'
            title='Master'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-crown'></i>
              </span>
            }
          />
          {/*
          <SidebarMenuItem
            to='purchase-orders/customer'
            title='Customer'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-chess-pawn'></i>
              </span>
            }
          />
          */}
          <SidebarMenuItem
            to='purchase-orders/orders'
            title='Customer Orders'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-chess-pawn'></i>
              </span>
            }
          />
        </SidebarMenuItemWithSub>
      )}
      {/*################# PO Management End #################*/}

      {/*################# Customer PO Management Start #################*/}

      <SidebarMenuItem
        to='purchase-orders/customer-view'
        title='Customer PO'
        roleNeeded={['customer']}
        customIconHtml={
          <span className='menu-icon'>
            <i className='fa-solid fa-people-carry'></i>
          </span>
        }
      />
      {/*################# Customer PO Management End #################*/}

      <SidebarMenuItem
        to='/consumer-metrics'
        title='Consumer Metrics'
        roleNeeded={['admin', 'vendor']}
        customIconHtml={
          <span className='menu-icon'>
            <i className='fa-solid fa-people-group'></i>
          </span>
        }
        vendorNameNeeded={['Luxottica', 'Thelios']}
      />

      <SidebarMenuItem
        to='/ai-forecasting'
        icon='chart-simple-2'
        title='AI Forecasting'
        fontIcon='bi-app-indicator'
        vendorNameNeeded={['Luxottica']}
      />

      {currentUser?.decoded?.vendorName === 'Designer Eyes' && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-uppercase fs-8 ls-1'>APPS</span>
          </div>
        </div>
      )}

      <SidebarMenuItem
        to='apps/product-data-report'
        icon='chart-simple-2'
        title='Product Data Report'
        fontIcon='bi-app-indicator'
        vendorNameNeeded={['Designer Eyes']}
      />

      {/* @@@@@@@@@@@@@ Amazon tools START @@@@@@@@@@@@@@ */}
      <SidebarMenuItemWithSub
        to='#'
        title='Amazon Tools'
        customIconHtml={
          <span className='menu-icon'>
            <i className='fab fa-amazon'></i>
          </span>
        }
      >
        {/* $$$$$$$$$ REPRICER START $$$$$$$$$ */}
        <SidebarMenuItemWithSub
          to='#'
          title='Repricers'
          customIconHtml={
            <span className='menu-icon'>
              <i className='fa-solid fa-tags'></i>
            </span>
          }
          vendorNameNeeded={['Designer Eyes']}
          roleNeeded={['admin']}
        >
          <SidebarMenuItem
            to='apps/repricer'
            title='EC-AMZ Repricer'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-tag'></i>
              </span>
            }
            vendorNameNeeded={['Designer Eyes']}
            roleNeeded={['admin']}
          />
        </SidebarMenuItemWithSub>
        {/* $$$$$$$$$ REPRICER END $$$$$$$$$ */}

        <SidebarMenuItem
          to='apps/invoice-processing'
          title='Invoice Processor'
          icon='update-file'
          vendorNameNeeded={['Designer Eyes']}
        />
        <SidebarMenuItem
          to='apps/retail-readiness-report'
          icon='chart-simple-2'
          title='Retail Readiness Report'
          fontIcon='bi-app-indicator'
          vendorNameNeeded={['Designer Eyes']}
        />
      </SidebarMenuItemWithSub>
      {/* @@@@@@@@@@@@@ Amazon tools END @@@@@@@@@@@@@@ */}

      {/* @@@@@@@@@@@@@ BETA APPS START @@@@@@@@@@@@@@ */}

      {currentUser?.decoded?.role === 'admin' ? (
        <>
          <SidebarMenuItemWithSub
            to='#'
            title='BETA APPS'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-flask'></i>
              </span>
            }
            vendorNameNeeded={['Designer Eyes']}
            roleNeeded={['admin']}
          >
            <SidebarMenuItem
              to='apps/amz-product-analysis'
              title='Product Analyzer(BETA)'
              fontIcon='bi-app-indicator'
              customIconHtml={
                <span className='menu-icon'>
                  <i className='fab fa-amazon'></i>
                </span>
              }
              vendorNameNeeded={['Designer Eyes']}
            />
            <SidebarMenuItem
              to='apps/amz-masterfile-generator'
              title='Masterfile Generator(BETA)'
              customIconHtml={
                <span className='menu-icon'>
                  <i className='fab fa-amazon'></i>
                </span>
              }
              vendorNameNeeded={['Designer Eyes']}
            />
            <SidebarMenuItem
              to='apps/map-violations'
              title='Map Violation Checker'
              customIconHtml={
                <span className='menu-icon'>
                  <i className='fab fa-amazon'></i>
                </span>
              }
              vendorNameNeeded={['Designer Eyes']}
            />
          </SidebarMenuItemWithSub>
        </>
      ) : null}

      {/*################# Distribution Start #################*/}
      <SidebarMenuItemWithSub
        to='#'
        title='Distribution'
        customIconHtml={
          <span className='menu-icon'>
            <i className='fa-solid fa-building-circle-arrow-right'></i>
          </span>
        }
        vendorNameNeeded={['Thelios']}
      >
        <SidebarMenuItem
          to='/distribution/caribbean'
          title='Caribbean'
          customIconHtml={<span className='menu-icon'>🇧🇶</span>}
          roleNeeded={['admin', 'vendor']}
        />
        <SidebarMenuItem
          to='distribution/israel'
          title='Israel'
          customIconHtml={<span className='menu-icon'>🇮🇱</span>}
          roleNeeded={['admin', 'vendor']}
        />
      </SidebarMenuItemWithSub>
      {/*################# Distribtuion End #################*/}

      {(currentUser?.decoded?.vendorName === 'Thelios' ||
        currentUser?.decoded?.vendorName === 'Luxottica' ||
        currentUser?.decoded?.vendorName === 'Costa') && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-uppercase fs-8 ls-1'>Retail</span>
            </div>
          </div>

          <SidebarMenuItem
            to='/retail-performance'
            title='Brand Performance'
            customIconHtml={
              <span className='menu-icon text-blue'>
                <i className='fa fa-tags'></i>
              </span>
            }
            vendorNameNeeded={['Thelios']}
          />
          <SidebarMenuItem
            to='/inventory-insights'
            title='Inventory Insights'
            roleNeeded={['admin', 'vendor']}
            customIconHtml={
              <span className='menu-icon text-blue'>
                <i className='fa fa-tags'></i>
              </span>
            }
          />
        </>
      )}
      {/*/////////////////// Amazon Start ///////////////////////*/}
      {(currentUser?.decoded?.vendorName === 'Luxottica' ||
        currentUser?.decoded?.vendorName === 'Designer Eyes') && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-uppercase fs-8 ls-1'>Business Intelligence</span>
          </div>
        </div>
      )}
      <SidebarMenuItem
        to='/brands'
        title='Brands Research'
        customIconHtml={
          <span className='menu-icon'>
            <i className='fab fa-amazon'></i>
          </span>
        }
        vendorNameNeeded={['Luxottica', 'Designer Eyes']}
      />
      <SidebarMenuItem
        to='/sellers'
        title='Seller Research'
        customIconHtml={
          <span className='menu-icon text-blue'>
            <i className='fab fa-amazon'></i>
          </span>
        }
        vendorNameNeeded={['Luxottica', 'Designer Eyes']}
      />
      {/*/////////////////// Amazon End ///////////////////////*/}

      {/*/////////////////// Amazon Costa Start ///////////////////////*/}
      {currentUser?.decoded?.vendorName === 'Costa' && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-uppercase fs-8 ls-1'>Business Intelligence</span>
          </div>
        </div>
      )}
      <SidebarMenuItem
        to='/brands/costa'
        title='Product Research'
        customIconHtml={
          <span className='menu-icon'>
            <i className='fab fa-amazon'></i>
          </span>
        }
        vendorNameNeeded={['Costa']}
      />
      <SidebarMenuItem
        to='/sellers/costa'
        title='Seller Research'
        customIconHtml={
          <span className='menu-icon text-blue'>
            <i className='fab fa-amazon'></i>
          </span>
        }
        vendorNameNeeded={['Costa']}
      />
      {/*/////////////////// Amazon Costa End ///////////////////////*/}

      {/* ################# Resources Start #################
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-uppercase fs-8 ls-1'>Resources</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/catalog'
        icon='update-file'
        title={intl.formatMessage({id: 'MENU.CATALOG'})}
        fontIcon='bi-app-indicator'
        roleNeeded={['admin', 'vendor']}
      />
      <SidebarMenuItem
        to='/image-upload'
        icon='update-file'
        title='Image Upload'
        fontIcon='bi-app-indicator'
        roleNeeded={['admin']}
      />

      <SidebarMenuItem
        to='/changelog'
        title='Updates'
        customIconHtml={
          <span className='menu-icon'>
            <i className='fa-solid fa-clock-rotate-left'></i>
          </span>
        }
      /> */}

      {currentUser?.decoded?.role === 'admin' ? (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-uppercase fs-8 ls-1'>Admin Center</span>
            </div>
          </div>
          <SidebarMenuItem
            to='/user-management'
            title='Users'
            customIconHtml={
              <span className='menu-icon'>
                <i className='fa-solid fa-crown'></i>
              </span>
            }
          />
        </>
      ) : null}
    </>
  )
}

export {SidebarMenuMain}
