import {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {DrawerComponent} from '../../../assets/ts/components'
import {WithChildren} from '../../../helpers'
import {useAuth} from '../../../../app/modules/auth'

const InventoryManagementReport = () => (
  <div id='kt_app_content' className='center'>
    <div>
      <iframe
        title='Inventory Management Report'
        width='100%'
        height='800px'
        src='https://app.powerbi.com/reportEmbed?reportId=b44db549-f6b3-48fa-b77b-9fa4e5d9194a&autoAuth=true&ctid=8ea736fe-1f18-4787-a692-4a16c6f0b7f1'
        frameBorder='0'
        allowFullScreen
      ></iframe>
    </div>
  </div>
)

const Content = ({children}: WithChildren) => {
  const {config, classes} = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])
  const {currentUser} = useAuth()

  const appContentContainer = config.app?.content?.container

  if (location.pathname === '/dashboard' && currentUser?.decoded?.vendorName === 'Designer Eyes') {
    return <InventoryManagementReport />
  }

  return (
    <div
      id='kt_app_content'
      className={clsx(
        'app-content flex-column-fluid',
        classes.content.join(' '),
        config?.app?.content?.class
      )}
    >
      {appContentContainer ? (
        <div
          id='kt_app_content_container'
          className={clsx('app-container', classes.contentContainer.join(' '), {
            'container-xxl': appContentContainer === 'fixed',
            'container-fluid': appContentContainer === 'fluid',
          })}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export {Content}
